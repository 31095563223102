import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import CostsRevenueChart from 'Components/CostsRevenueChart';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { IndicatorsWrapper } from './style';
const getFieldValue = (revenue, cost, field) => {
    const revenueData = revenue.data;
    const costData = cost.data;
    const currentRevenue = revenueData.sort((a, b) => b.year - a.year)?.[0];
    const currentCost = costData.sort((a, b) => b.year - a.year)?.[0];
    if (field === 'created_at') {
        return formatDate(currentRevenue?.created_at ?? currentCost?.created_at);
    }
    if (field === 'value') {
        if (currentRevenue && currentCost && currentRevenue?.year === currentCost?.year) {
            return formatCurrency(currentRevenue?.value - currentCost?.value);
        }
        return '---';
    }
    if (field === 'percentage_change') {
        if (currentRevenue && currentCost && currentRevenue?.year === currentCost?.year) {
            const lastYearRevenue = revenueData.sort((a, b) => b.year - a.year)?.[1];
            const lastYearCost = costData.sort((a, b) => b.year - a.year)?.[1];
            if (lastYearRevenue && lastYearCost && lastYearRevenue?.year === lastYearCost?.year) {
                const ratio = (lastYearRevenue?.value - lastYearCost?.value) /
                    (currentRevenue?.value - currentCost?.value);
                if (isNaN(ratio)) {
                    return '---';
                }
                return formatCurrency(ratio * 100, false, 'decimal');
            }
            return '---';
        }
    }
    return '';
};
export default function CostsRevenueSection({ revenue, cost }) {
    return (_jsxs(WhiteBlock, { style: { paddingBottom: '5em', padding: '1em' }, children: [_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: `${t('CustomerDetails.Revenue')} vs. ${t('CustomerDetails.Costs')}`, lastUpdate: `${t('CustomerDetails.LastUpdate')} ${revenue ? getFieldValue(revenue, cost, 'created_at') : ''}`, status: revenue.status, percentage: getFieldValue(revenue, cost, 'percentage_change'), text: t('CustomerDetails.CurrentYear') }) }), _jsx(CostsRevenueChart, { cost: cost.data, revenue: revenue.data }), _jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    gap: '2rem',
                    paddingLeft: 70,
                }, children: [_jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Revenue'), color: theme.colors.primary }), _jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Costs'), color: theme.colors.lightBlue })] })] }));
}
