import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMeasure } from '@uidotdev/usehooks';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryTooltip } from 'victory';
import theme from 'Global/theme';
import { formatCurrency } from 'Global/utils';
const normalizeData = (cost, revenue) => {
    const data = [];
    cost.forEach((item) => {
        data.push({
            type: 'cost',
            value: item.value,
            year: item.year,
        });
    });
    revenue.forEach((item) => {
        data.push({
            type: 'revenue',
            value: item.value,
            year: item.year,
        });
    });
    return data;
};
export default function CostsRevenueChart({ cost, revenue }) {
    const normalizedData = normalizeData(cost, revenue);
    const [ref, { width, height }] = useMeasure();
    const formatTick = (tick) => {
        return formatCurrency(tick, false);
    };
    return (_jsx("div", { ref: ref, style: { aspectRatio: 1.5 }, children: _jsxs(VictoryChart, { width: width ?? 0, height: height ?? 0, domainPadding: { x: 40, y: 20 }, padding: { left: 70, top: 20, right: 20, bottom: 50 }, children: [_jsx(VictoryAxis, { style: {
                        axis: { stroke: 'transparent' },
                        tickLabels: { fill: theme.colors.gray, fontSize: '1rem', padding: 5 },
                        axisLabel: { fontSize: '1rem' },
                    }, tickFormat: normalizedData.map((item) => item.year), orientation: 'bottom', offsetY: 50 }), _jsx(VictoryAxis, { dependentAxis: true, style: {
                        axis: { stroke: 'transparent' },
                        grid: { stroke: '#EEEEEE', strokeDasharray: '2,2' },
                        tickLabels: { fill: theme.colors.gray, fontSize: '1rem', padding: 5 },
                        axisLabel: { fontSize: '1rem' },
                    }, tickFormat: formatTick }), _jsxs(VictoryGroup, { offset: 26, colorScale: 'qualitative', children: [_jsx(VictoryBar, { cornerRadius: { top: 10, bottom: 10 }, data: normalizedData.filter((item) => item.type === 'revenue'), x: 'year', y: 'value', labels: ({ datum }) => formatCurrency(datum.value, false), labelComponent: _jsx(VictoryTooltip, { style: { fill: theme.colors.gray }, dy: -5 }), style: { data: { fill: theme.colors.primary, width: 20 } } }), _jsx(VictoryBar, { cornerRadius: { top: 10, bottom: 10 }, data: normalizedData.filter((item) => item.type === 'cost'), x: 'year', y: 'value', labels: ({ datum }) => formatCurrency(datum.value, false), labelComponent: _jsx(VictoryTooltip, { style: { fill: theme.colors.gray }, dy: -5 }), style: { data: { fill: theme.colors.lightBlue, width: 20 } } })] })] }) }));
}
