import { SignJWT, importPKCS8 } from 'jose';
import { REACT_APP_IAP_TOKEN } from 'Global/envVars';
export default async function getIdToken() {
    const iapClientId = '633511873537-fjh6lhle4mm7ihsvotjtp98s9csao2rt.apps.googleusercontent.com';
    const oauthTokenUri = 'https://www.googleapis.com/oauth2/v4/token';
    const ServiceAccountKey = JSON.parse(atob(REACT_APP_IAP_TOKEN || ''));
    const issuedAt = Math.floor(Date.now() / 1000);
    const expiresAt = issuedAt + 600;
    const payload = {
        iss: ServiceAccountKey.client_email,
        sub: ServiceAccountKey.client_email,
        aud: oauthTokenUri,
        exp: expiresAt,
        iat: issuedAt,
        target_audience: iapClientId,
    };
    const privateKey = await importPKCS8(ServiceAccountKey.private_key, 'RS256');
    const jwt = await new SignJWT(payload)
        .setProtectedHeader({ alg: 'RS256', kid: ServiceAccountKey.private_key_id })
        .sign(privateKey);
    const data = new URLSearchParams({
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: jwt,
    });
    const response = await fetch(oauthTokenUri, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
    });
    if (!response.ok) {
        const error = await response.json();
        throw new Error(`Error: ${error.error} - ${error.error_description}`);
    }
    const { id_token } = await response.json();
    return 'Bearer ' + id_token;
}
