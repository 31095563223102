import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import { IndicatorsWrapper } from 'Components/CostsRevenueSection/style';
import LiquidityChart from 'Components/LiquidityChart';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { DotsContainer } from './style';
function LiquiditySection({ overall, status }) {
    const { t } = useTranslation();
    const formmtGraphMonth = (time) => {
        const date = new Date(time);
        const year = date.getFullYear().toString().substring(2);
        const month = t(`Months.${date.getMonth()}`);
        return `${month} ${year}`;
    };
    const percentageChange = useMemo(() => {
        const data = overall.balances.overall.monthly_data;
        const d = new Date();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const currentDate = `${year}-${month}-15`;
        const result = data
            ? data.find((item) => item.month === currentDate)
            : [];
        return result ? result.percentage_change?.toString() || 'No data' : 'No data';
    }, [overall]);
    return (_jsxs(WhiteBlock, { children: [overall.balances.overall && (_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: t('CustomerDetails.Liquidity'), amount: formatCurrency(overall.balances.overall.current_liquidity?.current_balance), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${formatDate(overall.balances.overall.current_liquidity?.current_date)}`, status: overall.balances.overall.liquidity_status, percentage: percentageChange, text: percentageChange === '0'
                        ? t('CustomerDetails.NoChange')
                        : `${percentageChange}% ${t('CustomerDetails.CurrentMonth')}` }) })), overall.balances && (_jsx(LiquidityChart, { data: overall.balances.overall.monthly_data, formatXItem: formmtGraphMonth, formatYItem: (t) => formatCurrency(t, true), graphColor: status === 'critical'
                    ? theme.colors.error
                    : status === 'uncritical'
                        ? theme.colors.success
                        : theme.colors.warning })), _jsxs(DotsContainer, { style: { paddingLeft: 70 }, children: [_jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Liquidity'), color: status === 'critical'
                            ? theme.colors.error
                            : status === 'uncritical'
                                ? theme.colors.success
                                : theme.colors.warning }), _jsx(ColoredDotLabel, { fontSize: '17', text: t('CustomerDetails.Prognose'), color: status === 'critical'
                            ? `${theme.colors.error}99`
                            : status === 'uncritical'
                                ? `${theme.colors.success}99`
                                : `${theme.colors.warning}99` })] })] }));
}
export default LiquiditySection;
